/* line 37, src/assets/sass/sass/extensions/bulma-steps.sass */

.steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 1rem;
    min-height: 2rem
}
    /* line 163, src/assets/sass/sass/utilities/mixins.sass */

    .steps:not(:last-child) {
        margin-bottom: 1.5rem
    }
    /* line 42, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps .step-item {
        margin-top: 0;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;

        @media (max-width: 480px){
            & {
                display: flex;
                flex-basis: 100% !important;
                .step-details {
                    margin: 0 !important;
                    padding: 0 !important;
                }
                .step-marker {
                    margin-right: 10px;
                    position: relative !important;
                    left: initial !important;
                }
            }
            &:not(.is-active) {
                // display: none;
            }
        }
    }
        /* line 47, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item:not(:first-child) {
            -ms-flex-preferred-size: 1em;
            flex-basis: 1em;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-negative: 1;
            flex-shrink: 1
        }
            /* line 51, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps .step-item:not(:first-child)::before {
                content: " ";
                display: block;
                position: absolute;
                @media (max-width: 480px) {
                    display: none !important;
                }
            }
        /* line 57, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #0F2850));
            background: linear-gradient(to left, #dbdbdb 50%, #0F2850 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
            /* line 61, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps .step-item::before .step-marker {
                color: #fff
            }
        /* line 64, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-active::before {
            background-position: left bottom
        }
        /* line 66, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-active .step-marker {
            background-color: #fff;
            border-color: #8D99AE;
            color: #8D99AE
        }
        /* line 71, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-completed::before {
            background-position: left bottom
        }
        /* line 73, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-completed .step-marker {
            color: #fff;
            background-color: #8D99AE
        }
        /* line 77, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item .step-marker {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            border-radius: 50%;
            font-weight: 700;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            background: #b5b5b5;
            color: #fff;
            border: 0.2em solid #fff;
            z-index: 1
        }
        /* line 88, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item .step-details {
            text-align: center
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-white::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #fff));
            background: linear-gradient(to left, #dbdbdb 50%, #fff 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-white.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-white.is-active .step-marker {
            background-color: #fff;
            border-color: #fff;
            color: #fff
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-white.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-white.is-completed .step-marker {
            color: #0a0a0a;
            background-color: #fff
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-black::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #0a0a0a));
            background: linear-gradient(to left, #dbdbdb 50%, #0a0a0a 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-black.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-black.is-active .step-marker {
            background-color: #fff;
            border-color: #0a0a0a;
            color: #0a0a0a
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-black.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-black.is-completed .step-marker {
            color: #fff;
            background-color: #0a0a0a
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-light::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #f5f5f5));
            background: linear-gradient(to left, #dbdbdb 50%, #f5f5f5 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-light.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-light.is-active .step-marker {
            background-color: #fff;
            border-color: #f5f5f5;
            color: #f5f5f5
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-light.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-light.is-completed .step-marker {
            color: rgba(0,0,0,0.7);
            background-color: #f5f5f5
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-dark::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #363636));
            background: linear-gradient(to left, #dbdbdb 50%, #363636 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-dark.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-dark.is-active .step-marker {
            background-color: #fff;
            border-color: #363636;
            color: #363636
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-dark.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-dark.is-completed .step-marker {
            color: #fff;
            background-color: #363636
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-primary::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #00d1b2));
            background: linear-gradient(to left, #dbdbdb 50%, #00d1b2 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-primary.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-primary.is-active .step-marker {
            background-color: #fff;
            border-color: #00d1b2;
            color: #00d1b2
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-primary.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-primary.is-completed .step-marker {
            color: #fff;
            background-color: #00d1b2
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-link::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #3273dc));
            background: linear-gradient(to left, #dbdbdb 50%, #3273dc 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-link.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-link.is-active .step-marker {
            background-color: #fff;
            border-color: #3273dc;
            color: #3273dc
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-link.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-link.is-completed .step-marker {
            color: #fff;
            background-color: #3273dc
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-info::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #3298dc));
            background: linear-gradient(to left, #dbdbdb 50%, #3298dc 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-info.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-info.is-active .step-marker {
            background-color: #fff;
            border-color: #3298dc;
            color: #3298dc
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-info.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-info.is-completed .step-marker {
            color: #fff;
            background-color: #3298dc
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-success::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #48c774));
            background: linear-gradient(to left, #dbdbdb 50%, #48c774 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-success.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-success.is-active .step-marker {
            background-color: #fff;
            border-color: #48c774;
            color: #48c774
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-success.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-success.is-completed .step-marker {
            color: #fff;
            background-color: #48c774
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-warning::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #ffdd57));
            background: linear-gradient(to left, #dbdbdb 50%, #ffdd57 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-warning.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-warning.is-active .step-marker {
            background-color: #fff;
            border-color: #ffdd57;
            color: #ffdd57
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-warning.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-warning.is-completed .step-marker {
            color: rgba(0,0,0,0.7);
            background-color: #ffdd57
        }
        /* line 96, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-danger::before {
            background: -webkit-gradient(linear, right top, left top, color-stop(50%, #dbdbdb), color-stop(50%, #f14668));
            background: linear-gradient(to left, #dbdbdb 50%, #f14668 50%);
            background-size: 200% 100%;
            background-position: right bottom
        }
        /* line 101, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-danger.is-active::before {
            background-position: left bottom
        }
        /* line 103, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-danger.is-active .step-marker {
            background-color: #fff;
            border-color: #f14668;
            color: #f14668
        }
        /* line 108, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-danger.is-completed::before {
            background-position: left bottom
        }
        /* line 110, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item.is-danger.is-completed .step-marker {
            color: #fff;
            background-color: #f14668
        }
    /* line 114, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps .steps-content {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin: 2rem 0;
        min-height: 365px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
        /* line 118, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .steps-content .step-content {
            display: none
        }
        
            /* line 120, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps .steps-content .step-content.is-active {
                display: block
            }
            
            .steps .steps-content .step-content.step-content-small {
                width: 500px;
                max-width: 100%;
                margin-right: auto;
                margin-left: auto;

                @media(max-width: 480px) {
                    width: 100%;
                }
            }
            .steps .steps-content .step-content.step-content-medium {
                width: 750px;
                max-width: 100%;
                margin-right: auto;
                margin-left: auto;

                @media(max-width: 480px) {
                    width: 100%;
                }
            }

    /* line 123, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps .steps-actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
        /* line 127, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .steps-actions .steps-action {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            margin: .5rem;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center
        }
    /* line 137, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps.is-animated .step-item::before {
        -webkit-transition: all 2s ease;
        transition: all 2s ease
    }
    /* line 139, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps.is-animated .step-item .step-marker {
        -webkit-transition: all 0s ease;
        transition: all 0s ease;
        -webkit-transition-delay: 1.5s;
        transition-delay: 1.5s
    }
    /* line 13, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps .step-item:not(:first-child)::before {
        height: .2em;
        width: 100%;
        bottom: 0;
        left: -50%;
        top: 1rem
    }
    /* line 19, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps .step-item .step-marker {
        height: 2rem;
        width: 2rem;
        position: absolute;
        left: calc(50% - 1rem)
    }
        /* line 25, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item .step-marker .icon * {
            font-size: 1rem
        }
    /* line 27, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps .step-item .step-details {
        margin-top: 2rem;
        margin-left: .5em;
        margin-right: .5em;
        padding-top: .2em
    }
        /* line 32, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps .step-item .step-details .step-title {
            font-size: 1.2rem;
            font-weight: 600
        }
    /* line 144, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps.is-small {
        font-size: .75rem;
        min-height: 1.5rem
    }
        /* line 13, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-small .step-item:not(:first-child)::before {
            height: .2em;
            width: 100%;
            bottom: 0;
            left: -50%;
            top: .75rem
        }
        /* line 19, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-small .step-item .step-marker {
            height: 1.5rem;
            width: 1.5rem;
            position: absolute;
            left: calc(50% - .75rem)
        }
            /* line 25, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps.is-small .step-item .step-marker .icon * {
                font-size: .75rem
            }
        /* line 27, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-small .step-item .step-details {
            margin-top: 1.5rem;
            margin-left: .5em;
            margin-right: .5em;
            padding-top: .2em
        }
            /* line 32, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps.is-small .step-item .step-details .step-title {
                font-size: .9rem;
                font-weight: 600
            }
    /* line 146, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps.is-medium {
        font-size: 1.25rem;
        min-height: 2.5rem
    }
        /* line 13, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-medium .step-item:not(:first-child)::before {
            height: .2em;
            width: 100%;
            bottom: 0;
            left: -50%;
            top: 1.25rem
        }
        /* line 19, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-medium .step-item .step-marker {
            height: 2.5rem;
            width: 2.5rem;
            position: absolute;
            left: calc(50% - 1.25rem)
        }
            /* line 25, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps.is-medium .step-item .step-marker .icon * {
                font-size: 1.25rem
            }
        /* line 27, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-medium .step-item .step-details {
            margin-top: 2.5rem;
            margin-left: .5em;
            margin-right: .5em;
            padding-top: .2em
        }
            /* line 32, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps.is-medium .step-item .step-details .step-title {
                font-size: 1.5rem;
                font-weight: 600
            }
    /* line 148, src/assets/sass/sass/extensions/bulma-steps.sass */

    .steps.is-large {
        font-size: 1.5rem;
        min-height: 3rem
    }
        /* line 13, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-large .step-item:not(:first-child)::before {
            height: .2em;
            width: 100%;
            bottom: 0;
            left: -50%;
            top: 1.5rem
        }
        /* line 19, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-large .step-item .step-marker {
            height: 3rem;
            width: 3rem;
            position: absolute;
            left: calc(50% - 1.5rem)
        }
            /* line 25, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps.is-large .step-item .step-marker .icon * {
                font-size: 1.5rem
            }
        /* line 27, src/assets/sass/sass/extensions/bulma-steps.sass */

        .steps.is-large .step-item .step-details {
            margin-top: 3rem;
            margin-left: .5em;
            margin-right: .5em;
            padding-top: .2em
        }
            /* line 32, src/assets/sass/sass/extensions/bulma-steps.sass */

            .steps.is-large .step-item .step-details .step-title {
                font-size: 1.8rem;
                font-weight: 600
            }
/* line 1, src/assets/sass/sass/extensions/bulma-tagsinput.sass */

.tagsinput {
    height: auto !important
}
    /* line 3, src/assets/sass/sass/extensions/bulma-tagsinput.sass */

    .tagsinput .control {
        margin-bottom: .1em !important;
        margin-top: .1em !important
    }
    /* line 6, src/assets/sass/sass/extensions/bulma-tagsinput.sass */

    .tagsinput input {
        border: none;
        margin-bottom: .1em !important;
        margin-top: .1em !important
    }
    /* line 11, src/assets/sass/sass/extensions/bulma-tagsinput.sass */

    .tagsinput .tag.is-active {
        background-color: #00d1b2;
        color: #fff
    }

/*# sourceMappingURL=../../../sass */
.label-text {
    margin-left: 10px;
}