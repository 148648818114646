:root {
    --primary: #0f2850;
    --primary_variant: #2a41e8;
    --secondary: #78baeb;
    --secondary_variant: #33aed9;
    --sizeXXS: 8px;
    --sizeXS: 12px;
    --sizeS: 16px;
    --sizeM: 24px;
    --sizeL: 36px;
    --sizeXL: 48px;
    --sizeXXL: 60px;
}