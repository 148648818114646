@import './common/base';
@import './common/common';
@import './common/variables';
@import './custom/register';
@import '../assets/themify-icons/themify-icons';

@font-face {
	font-family: 'themify';
	src: url('../assets/themify-icons/fonts/themify.eot?-fvbane');
	src: url('../assets/themify-icons/fonts/themify.eot?#iefix-fvbane') format('embedded-opentype'),
		 url('../assets/themify-icons/fonts/themify.woff?-fvbane') format('woff'),
		 url('../assets/themify-icons/fonts/themify.ttf?-fvbane') format('truetype'),
		 url('../assets/themify-icons/fonts/themify.svg?-fvbane#themify') format('svg');
	font-weight: normal;
	font-style: normal;
}