html {
    // scroll-behavior: smooth;
}

.text-centered, .centered-title { text-align: center; }
.is-centered-self { align-self: center; }
.no-margin { margin: 0; }
.ti-check { color: #fccb28; }

// .fadeInUp {
//     opacity: 0;
//     animation-name: fadeInUp;
// }

.animated { 
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

section {
    padding: 4%;
    .content-wrapper {
        padding-top: 40px;
    }
    // @media (max-width: 1023px) {
    //     padding: 50px 18px;
    // }
}

.content {
    h2 {
        font-size: 1.75em;
        margin-bottom: 4%;
    }
    h3:not(:first-child) {
        margin-top: 0;
        margin-bottom: 50px;
    }
}

.pb-10{padding-bottom: 0px !important;}
.pb-10{padding-bottom: 10px !important;}
.pb-20{padding-bottom: 20px !important;}
.pb-30{padding-bottom: 30px !important;}
.pb-40{padding-bottom: 40px !important;}
.pb-50{padding-bottom: 50px !important;}
.pb-70 {padding-bottom: 70px !important;}
.pb-90 {padding-bottom: 90px !important;}
.pb-100{padding-bottom: 100px !important;}
.pb-150{padding-bottom: 150px !important;}
.pb-180{padding-bottom: 180px !important;}

.pt-10{padding-top: 10px;}
.pt-20{padding-top: 20px;}
.pt-30{padding-top: 30px;}
.pt-40{padding-top: 40px;}
.pt-50{padding-top: 50px;}
.pt-100{padding-top: 100px;}
.pt-150{padding-top: 150px;}

.mb-10{margin-bottom: 10px;}
.mb-20{margin-bottom: 20px;}
.mb-30{margin-bottom: 30px;}
.mb-40{margin-bottom: 40px;}
.mb-50{margin-bottom: 50px;}
.mb-80{margin-bottom: 80px;}
.mb-100{margin-bottom: 100px;}

.mt-10{margin-top: 10px;}
.mt-20{margin-top: 20px;}
.mt-30{margin-top: 30px;}
.mt-40{margin-top: 40px;}
.mt-50{margin-top: 50px;}
.mt-80{margin-top: 80px;}
.mt-100{margin-top: 100px;}

.fs-12{font-size: 12px !important;}
.fs-24{font-size: 24px !important;}
.fs-36{font-size: 36px !important;}
.fs-48{font-size: 48px !important;}